<template>
  <AdvanceTable ref="advanceTable" url="login-logs" :height="window.height - 310" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" v-loading="isLoading">
    <template #title>
      {{ $t("Login Logs") }}
    </template>

    <template #cell(origin_countries)="row">
      {{ row.value.map(c => c.countryCode).join(', ') }}
    </template>
  </AdvanceTable>
</template>

<script>
import { BModal } from "bootstrap-vue";
import moment from "moment/moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Prompt from "@/views/erp/mixins/Prompt";
import axios from "axios";
import AdvanceTable from "@/views/components/advanceTable/advanceTable.vue";

export default {
  components: {
    AdvanceTable,
    BModal, moment,
    ToastificationContent
  },
  mixins: [Prompt],
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      isLoading: false,
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  mounted() {
  }
}
</script>
